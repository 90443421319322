<template>
  <div
    v-if="showFilter"
    ref="parent"
    class="auction-list-filter ml-auto flex w-full flex-col items-start p-2"
  >
    <AuctionListFilterHeader />
    <div v-if="data && isOpen" data-testid="filters" class="w-full">
      <HeadlessTabGroup v-slot="{ selectedIndex }">
        <HeadlessTabList class="flex flex-col @md:flex-row">
          <AuctionListFilterTab
            v-for="(item, index) in items"
            :key="`tab-list-item-${item.title}`"
          >
            <template #header>
              <span
                class="text-lg font-semibold @md:text-base @md:font-normal"
                >{{ $t(item.title) }}</span
              >
            </template>
            <template #content>
              <div
                v-if="selectedIndex === index"
                class="w-full bg-white p-2 @md:hidden"
              >
                <Component :is="item.component" />
              </div>
            </template>
          </AuctionListFilterTab>
        </HeadlessTabList>

        <HeadlessTabPanels class="-mt-2 hidden @md:block">
          <HeadlessTabPanel
            v-for="item in items"
            :key="`tab-panel-item-${item.title}`"
          >
            <Component :is="item.component" />
          </HeadlessTabPanel>
        </HeadlessTabPanels>
        <AuctionListFilterChips />
      </HeadlessTabGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import type { Ref } from 'vue'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import AuctionType from './filters/AuctionType.vue'
import AuctionDate from './filters/AuctionDate.vue'
import AuctionCountry from './filters/AuctionCountry.vue'
import CarType from './filters/CarType.vue'
import AuctionNumber from './filters/AuctionNumber.vue'
import AuctionStatus from './filters/AuctionStatus.vue'
import AuctionListFilterTab from './AuctionListFilterTab.vue'
import AuctionBrand from './filters/AuctionBrand.vue'
import AuctionName from './filters/AuctionName.vue'
import AuctionListFilterChips from './chips/AuctionListFilterChips.vue'
import AuctionListFilterHeader from './AuctionListFilterHeader.vue'

const appIds = inject<Ref<number[]>>('appIds')

const items = [
  {
    title: 'auction-list.auction-type',
    component: AuctionType
  },
  {
    title: 'auction-list.auction-date',
    component: AuctionDate
  },
  {
    title: 'auction-list.auction-country',
    component: AuctionCountry
  },
  {
    title: 'auction-list.car-type',
    component: CarType
  },
  {
    title: 'auction-list.auction-name',
    component: AuctionName
  },
  {
    title: 'auction-list.auction-number',
    component: AuctionNumber
  },
  {
    title: 'auction-list.auction-status',
    component: AuctionStatus
  },
  {
    title: 'auction-list.auction-brand',
    component: AuctionBrand
  }
]

const [parent] = useAutoAnimate()

const auctionStoreKey = inject('auctionStoreKey')
const { isOpen } = storeToRefs(useAuctionStore(auctionStoreKey))
const { $customFetch } = useCustomFetch()

const { locale } = useI18n()

const { data } = useQuery(['auctionListFilter', locale.value, appIds], () =>
  $customFetch<ParsedFilterData>('/api/auctions-filter', {
    query: {
      lang: locale.value,
      appIds: appIds.value?.length ? appIds.value : undefined
    }
  })
)

provide('filterData', data)

const showFilter = computed(() => {
  return (
    Object.values(data.value ?? {})
      .map((item) => {
        if (Array.isArray(item)) {
          return item.length
        }
        return 1
      })
      .reduce((acc, curr) => acc + curr, 0) > 0
  )
})
</script>

<style lang="scss">
.auction-list-filter {
  * {
    user-select: none;
  }
}
</style>
